import React, {useState} from 'react';
import WT from "@sscale/wtsdk";
import {authRequest} from "./API/TokenService";
import {useStorage} from "../hooks/useStorage";
import CustomInput from "./UI/input/customInput";
import CustomButton from "./UI/button/customButton";

const UserInputs = () => {

    const defaultConnectionParams = {
        authUrl: "https://auth.s1.sceenic.co/token/v2/",
        authToken: "rqouSTUwTrmNVCQK8Lt/lw==",
        sessionId: 'synamedia_test_room',
        streamingToken: "",
        participantName: ""
    }


    const [connectionParams, setConnectionParams] = useState({...defaultConnectionParams})
    const [connected, setConnected ] = useState(false)
    useStorage(setConnectionParams);

    const onConnect = async (event) => {
        event.preventDefault();
            const {streaming_token = '' } = await authRequest(
                connectionParams.authUrl,
                connectionParams.authToken,
                connectionParams.sessionId
            );
            console.log("xAuth token", streaming_token)
            streaming_token
                ? WT.Session.connect(streaming_token, connectionParams.participantName)
                : alert("Unable to get token from xAuth!")

        setConnected(true)
    }

    const onDisconnect = (event) => {
        event.preventDefault();
        WT.Session.disconnect();
        setConnected(false)
    }

    const onChangeParticipantName = (event) => {
        setConnectionParams({...connectionParams, participantName: event.target.value})
    }

    return (
        <div className={'input-content-container'}>
            {/*<TokenForm setConnectionParams={setConnectionParams} connectionParams={connectionParams}/>*/}
            {/*<AuthForm setConnectionParams={setConnectionParams} connectionParams={connectionParams}/>*/}
            {!connected && <CustomInput


                id="participant"
                type="text"
                placeholder="Enter your namee"
                value={connectionParams.participantName}
                onChange={onChangeParticipantName}
                withLabel={false}
            />}
            <div className="buttons">
                {!connected && <CustomButton
                    // disabled={!connected}
                    onClick={onConnect}>
                    Connect
                </CustomButton>}
                {connected && <CustomButton
                    // disabled={!connected}
                    onClick={onDisconnect}>
                    Disconnect
                </CustomButton>}

            </div>
        </div>
    );
};

export default UserInputs;
