import React from 'react';
import UserInputs from "./UserInputs";
import VideoGrid from "./VideoGrid";
import VideoControls from "./VideoControls";
import {useListeners} from "../hooks/useListeners";

const Session = () => {

    const participants = useListeners();


    return (
        <div className={'session-container'}>
            <div className='powered-by-container'>
                <div className='powered-by-text'>Powered by</div>
                <div className='logo-top'>
                </div>

            </div>
            <VideoGrid participants={participants}/>
            <UserInputs/>
        </div>
    );
};

export default Session;
