import {useEffect, useState} from "react";
import WT from "@sscale/wtsdk";


export function useListeners() {

    const [participants, setParticipants] = useState([])

    useEffect(() => {
        WT.SessionListeners.onConnected(() => {
            WT.SessionListeners.onStreamCreated(participant => {
                setParticipants(prevParticipants => ([
                    ...prevParticipants,
                    participant
                ]));
            });
            WT.ParticipantListeners.onParticipantLeft(({participantId}) => {
                setParticipants(prevParticipant => (
                    prevParticipant.filter((p) => p.participantId !== participantId)
                ));
            });
        });
        WT.SessionListeners.onDisconnected(() => {
            setParticipants([])
        })
    }, [])

    return participants;
}