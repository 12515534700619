import axios from "axios";

export async function authRequest(authUrl, authToken, sessionId) {
    const requestInstance = axios.create();
    requestInstance.defaults.baseURL=authUrl;
    requestInstance.defaults.headers.common['Auth-Token'] = authToken

    try {
        const request = await requestInstance.post("", {room_name: sessionId})
        return await request.data
    } catch (err) {
        console.log(err)
    }
    return null;
}

export async function groutRequest(groutUrl, streamingToken) {
    console.log(groutUrl, streamingToken)
    const requestInstance = axios.create();
    requestInstance.defaults.baseURL=groutUrl;
    requestInstance.defaults.headers.common['Authorization'] = `Bearer ${streamingToken}`
    try {
        const request = await requestInstance.post("")
        const payload = await request.data
        console.log(payload)
        return payload
    } catch (err) {
        console.log(err);
    }
}
