import React from 'react';
import VideoContainer from "./VideoContainer";

const VideoGrid = ({participants}) => {

    return (
        <div className='video-grid'>
            {participants.length
                ? participants.map(user =>
                    user.stream && user.local?
                        <VideoContainer key={user.participantId} user={user}/>
                        : null)
                : null
            }
        </div>
    );
};

export default VideoGrid;
