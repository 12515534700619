import {useEffect} from "react";

export function useStorage(setConnectionParam) {
    useEffect(() => {
        setConnectionParam(prevState => {
            const newState = {}
            for (const [key, value] of Object.entries(prevState)) {
                let storageValue = localStorage.getItem(key);
                newState[key] = storageValue ? JSON.parse(storageValue) : value
            }
            return newState
        })
    }, [setConnectionParam])
}